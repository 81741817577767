import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../Header/Header";
import CircularProgress from "@mui/material/CircularProgress";

const StoragePlan = () => {
  const url = process.env.REACT_APP_BASEURL;
  const [storagePlan, setStoragePlan] = useState([]);
  const [msgData, setMsgData] = React.useState(null);

  const [load, setLoad] = useState(false);

  const handleGetStoragePlan = () => {
    setLoad(true);
    axios
      .get(`${url}/api/storage`)
      .then((res) => {
        setLoad(false);
        console.log(res?.data?.result);
        const modifiedData = res?.data?.result ?? [];
        setStoragePlan(modifiedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetStoragePlan();
  }, []);

  const handleUpdateStoragePlan = (plans, key, val, type) => {
    // Construct payload based on key
    const payload = {
      planId: plans?.planId,
      field: key,
      value:
        key === "teacher" || key === "student" || key === "superAdmin"
          ? {
              value: val, // For teacher, student, superAdmin
              type: type, // Unit type
            }
          : val,
    };
    onUpdateCreditPackage(payload);
  };

  const onUpdateCreditPackage = (payload) => {
    axios
      .put(`${url}/api/storage`, payload, {
        headers: { "x-access-token": window.sessionStorage.getItem("Token") },
      })
      .then((res) => {
        setMsgData(
          {
            message: "Package has been updated successfully",
            type: "Success",
          } || null
        );
        setTimeout(() => {
          setMsgData(null);
        }, 1500);
      });
  };

  const showMessage = () => {
    return (
      <div
        className={
          msgData?.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{ zIndex: "9999" }}
      >
        {msgData?.message}
        <button
          type="button"
          className="close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="mx-4" style={{ marginTop: "72px" }}>
        {msgData ? showMessage() : null}
        <h5 className="py-4">Storage plan</h5>
        {load ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <table className="table container">
            <thead>
              <tr className="table-secondary">
                <th scope="row"></th>
                {storagePlan?.length &&
                  storagePlan.map((item, index) => (
                    <th key={index} scope="row">
                      {item?.planName}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {storagePlan?.length &&
                Object.keys(storagePlan[0]).map((key, index) => {
                  return (
                    <tr key={index}>
                      {key !== "planId" &&
                        key !== "status" &&
                        key !== "planName" &&
                        key !== "externalSource" && (
                          <th className="topic" scope="row">
                            {key.toLocaleUpperCase()}
                          </th>
                        )}
                      {storagePlan.map((data, dataIndex) => {
                        return (
                          key !== "planId" &&
                          key !== "planName" &&
                          key !== "status" &&
                          key !== "externalSource" && (
                            <td key={dataIndex}>
                              <div className="d-flex align-items-center">
                                {/* Input for value */}
                                {key === "teacher" ||
                                key === "student" ||
                                key === "superAdmin" ? (
                                  <>
                                    <div
                                      contentEditable
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          e.target.blur();
                                          handleUpdateStoragePlan(
                                            data,
                                            key,
                                            e.target?.innerText,
                                            data[key].type
                                          );
                                        }
                                      }}
                                      className="mr-2"
                                    >
                                      {data[key].value}
                                    </div>

                                    {/* Dropdown for unit type */}
                                    <select
                                      value={data[key].type}
                                      onChange={(e) => {
                                        const type = e.target.value;
                                        // Update the selected option
                                        handleUpdateStoragePlan(
                                          data,
                                          key,
                                          data[key].value,
                                          type
                                        );
                                        //update storage plan
                                        const idx = storagePlan.findIndex(
                                          (plan) => plan.planId === data.planId
                                        );
                                        storagePlan[idx][key].type = type;
                                        setStoragePlan([...storagePlan]);
                                      }}
                                    >
                                      <option value="KB">KB</option>
                                      <option value="MB">MB</option>
                                      <option value="GB">GB</option>
                                      <option value="TB">TB</option>
                                    </select>
                                  </>
                                ) : (
                                  <div
                                    contentEditable
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.target.blur();
                                        handleUpdateStoragePlan(
                                          data,
                                          key,
                                          e.target?.innerText,
                                          data[key].type
                                        );
                                      }
                                    }}
                                  >
                                    {data[key]}
                                  </div>
                                )}
                              </div>
                            </td>
                          )
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default StoragePlan;
