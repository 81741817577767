import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import styled from "@emotion/styled";

export default function RejectCollectionDialog({
  open,
  handleClose,
  reasonInputChangehandler,
  handleReject,
}) {
  const SubmitRejectButton = styled(Button)({
    backgroundColor: "#585a96",
    color: "#fff",
    borderColor: "#585a96",
    "&:hover": {
      backgroundColor: "#484b99",
      borderColor: "#484b99",
      boxShadow: "none",
    },
  });

  const CancelRejectButton = styled(Button)({
    borderColor: "#585a96",
    color: "#585a96",
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle>Collection Rejection</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: "Karla" }}>
            Please mentions the reason for rejection!
          </DialogContentText>
          <TextField
            id="outlined-textarea"
            rows={4}
            autoFocus
            margin="dense"
            label="Reject reason"
            type="email"
            fullWidth
            variant="outlined"
            multiline
            onChange={(e) => {
              reasonInputChangehandler(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions sx={{ paddingRight: "23px", paddingBottom: "20px" }}>
          <CancelRejectButton variant="outlined" onClick={handleClose}>
            Cancel
          </CancelRejectButton>
          <SubmitRejectButton variant="contained" onClick={handleReject}>
            Submit
          </SubmitRejectButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
