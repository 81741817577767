import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import "./Settings.css";
import axios from "axios";
import { useHistory } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";

const Settings = () => {
  const url = process.env.REACT_APP_BASEURL;
  const history = useHistory();

  const [msgData, setMsgData] = React.useState(null);
  const [planName, setPlanName] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [extensionValue, setExtensionValue] = useState({
    id: "",
    title: "",
    value: "",
  });
  const [extensionNumber, setExtensionNumber] = useState({
    id: "",
    title: "",
    value: "",
  });
  const [isEdit, setIsEdit] = useState("");
  const [load, setLoad] = useState(false);

  useEffect(() => {
    getPlan();
  }, []);

  const handleKeyDown = (event, planId) => {
    setMsgData(null);
    if (event.key === "Enter") {
      const body = {
        planId: extensionNumber.id,
        extension: extensionNumber.title,
        value: +extensionNumber.value,
      };
      updateExtension(body, planId);
      setIsEdit("");
    }
  };

  const getPlan = () => {
    setLoad(true);
    axios
      .get(`${url}/api/admin/get-plan`, {
        headers: { "x-access-token": window.sessionStorage.getItem("Token") },
      })
      .then((res) => {
        setLoad(false);
        if (res.data?.planData) {
          setPlanName(res.data.planData?.planName);
          setPlanData(res.data.planData?.planData);
        }
      })
      .catch((err) => {
        setLoad(false);
        console.log(err, err.response);
        if (err?.response.status === 401) {
          history.push("/");
          sessionStorage.clear();
        }
      });
  };

  const handleExtension = (id, extension, value, planId) => {
    setMsgData(null);
    const body = { planId: id, extension, value };

    setExtensionValue({
      id,
      title: extension,
      value,
    });
    updateExtension(body, planId);
  };

  const updateExtension = (body, planId) => {
    const currentPlanIndex = planData.findIndex((plan) => plan.id === planId);
    if (currentPlanIndex !== -1) {
      const idx = planData[currentPlanIndex].planData.findIndex(
        (ele) => ele.id === body.planId
      );
      if (idx !== 1)
        planData[currentPlanIndex].planData[idx].value = body.value;
    }
    setPlanData([...planData]);

    axios
      .post(`${url}/api/admin/update-plan`, body, {
        headers: { "x-access-token": window.sessionStorage.getItem("Token") },
      })
      .then((res) => {
        setMsgData(
          {
            message: "Plan has been updated successfully",
            type: "Success",
          } || null
        );
      })
      .catch((err) => {
        console.log(err, err.response);
        if (err?.response.status === 401) {
          history.push("/");
          sessionStorage.clear();
        }
        setMsgData(
          { message: err.response.data.message, type: "error" } || null
        );
        setExtensionValue({
          id: "",
          title: "",
          value: "",
        });
      });
  };

  const handleReset = () => {
    axios
      .post(`${url}/api/admin/reset-plan`)
      .then((res) => {
        setPlanData(res.data?.planData?.planData);
        setMsgData(
          {
            message: "Plan has been reset successfully",
            type: "Success",
          } || null
        );
      })
      .catch((err) => {
        console.log(err, err.response);
        if (err?.response.status === 401) {
          history.push("/");
          sessionStorage.clear();
        }
        setMsgData(
          { message: err.response.data.message, type: "error" } || null
        );
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementsByClassName("alert")[0]?.classList.remove("show");
    }, 2000);
  }, [msgData]);

  const showMessage = () => {
    return (
      <div
        className={
          msgData.type === "Success"
            ? "alert alert-success fixed-bottom alert-dismissible fade show"
            : "alert alert-danger fixed-bottom alert-dismissible fade show"
        }
        role="alert"
        style={{
          zIndex: "9999",
          position: "sticky",
          right: "0",
          top: "72px",
          left: "100%",
          width: "fit-content",
        }}
      >
        {msgData.message}
        <button
          type="button"
          className="close"
          // data-dismiss="alert"
          // aria-label="Close"
          onClick={() =>
            document
              .getElementsByClassName("alert")[0]
              ?.classList.remove("show")
          }
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  };

  //this is not for plan wise it is for all plan same
  const forAllPlanData = [
    {
      title: "Students",
      beforeText: "Per teacher only",
      AfterText: "students",
    },
  ];

  return (
    <>
      <Header />

      <div className="mx-4" style={{ marginTop: "72px" }}>
        {msgData ? showMessage() : null}
        <h5 className="py-4">ACV Plans</h5>
        {load ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : (
          <>
            <table className="table container">
              <thead>
                <tr class="table-secondary">
                  <th scope="col"></th>
                  {planName &&
                    planName.length > 0 &&
                    planName.map((name) => <th scope="col">{name}</th>)}
                </tr>
              </thead>
              <tbody>
                {planData?.length &&
                  planData.map((item, index) => {
                    const forAllPlanindex = forAllPlanData.findIndex(
                      (ele) => ele.title === item.title
                    );
                    return (
                      forAllPlanindex == -1 && (
                        // item.title !== "Students" && (
                        <tr>
                          <td className="topic">{item.title.toUpperCase()}</td>
                          {item.planData.length > 0 &&
                            item.planData.map((data, i) => {
                              return data.type == "boolean" ? (
                                <td>
                                  <input
                                    className="form-check-input check"
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    checked={
                                      data.id === extensionValue.id &&
                                      item.title === extensionValue.title
                                        ? extensionValue.value
                                        : data.value
                                    }
                                    onChange={() =>
                                      handleExtension(
                                        data.id,
                                        item.title,
                                        !data.value,
                                        item.id
                                      )
                                    }
                                  />
                                </td>
                              ) : (
                                <td>
                                  {item.id + data.id !== isEdit ? (
                                    <label
                                      className="digit"
                                      onClick={(e) => {
                                        setIsEdit(item.id + data.id);
                                        setExtensionNumber({
                                          ...data,
                                          title: item.title,
                                        });
                                      }}
                                    >
                                      {data.value}
                                    </label>
                                  ) : (
                                    <div>
                                      <input
                                        className="input"
                                        type="text"
                                        value={extensionNumber.value}
                                        onChange={(e) =>
                                          setExtensionNumber({
                                            ...extensionNumber,
                                            value: e.target.value,
                                          })
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, item.id)
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              );
                            })}
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>

            {forAllPlanData.map((item, index) => {
              const id = planData.findIndex((ele) => ele.title === item.title);
              return (
                <>
                  <div className="table container student-div border-bottom">
                    <div
                      className="col-md-6"
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        paddingLeft: "0px",
                      }}
                    >
                      <p className="topic ">{item.title.toUpperCase()}</p>
                    </div>
                    <div
                      className="col-md-6"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <p className="students">
                        {item.beforeText}
                        {planData[id]?.id + planData[id]?.planData[0]?.id !==
                          isEdit && planData[id]?.title ? (
                          <label
                            className="digit-label"
                            onClick={(e) => {
                              setIsEdit(
                                planData[id]?.id + planData[id]?.planData[0]?.id
                              );
                              setExtensionNumber({
                                ...planData[id]?.planData[0],
                                title: planData[id]?.title,
                              });
                            }}
                          >
                            {planData[id]?.planData[0]?.value}
                          </label>
                        ) : (
                          <input
                            className="input"
                            type="text"
                            value={extensionNumber.value}
                            onChange={(e) =>
                              setExtensionNumber({
                                ...extensionNumber,
                                value: e.target.value,
                              })
                            }
                            onKeyDown={(e) =>
                              handleKeyDown(e, planData[id]?.id)
                            }
                          />
                        )}
                        {item.AfterText}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="container">
              <button
                type="button"
                className="btn btn-outline-secondary float-right mt-4 mb-4"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Settings;
