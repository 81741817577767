import React from "react";
import axios from "axios";
import { useHistory } from "react-router";
import TangibleITLogo from "../../../Assets/Images/TangibleITLogo192.png";
import "./Login.css";

const Login = () => {
  const history = useHistory();
  const url = process.env.REACT_APP_BASEURL;
  const [msgData, setMsgData] = React.useState(null);

  React.useEffect(() => {
    const tkn = window.sessionStorage.getItem("Token");
    const usr = window.sessionStorage.getItem("User");
    if (tkn && usr) {
      history.push("/coupons");
    } else {
      history.push("/");
    }
  }, []);

  const validateInfo = (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    axios
      .post(`${url}/api/admin/admin-login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        window.sessionStorage.setItem("User", res?.data.result.email);
        window.sessionStorage.setItem("Token", res?.data.result.token);
        history.push({
          pathname: "/coupons",
          state: { user: res.data.result.email, token: res.data.result.token },
        });
      })
      .catch((err) => {
        console.log(err, err.response);
        setMsgData(
          {
            message: err.response?.data.message,
            type: err.response?.status,
          } || null
        );
      });
  };
  return (
    <div className="container-fluid p-0 overflow-hidden text-left bg-acv h-100vh">
      {msgData ? (
        <div
          className={
            msgData?.type === "Success"
              ? "alert alert-success fixed-bottom alert-dismissible fade show"
              : "alert alert-danger fixed-bottom alert-dismissible fade show"
          }
          role="alert"
          style={{ zIndex: "9999" }}
        >
          {msgData?.message}
          <button
            type="button"
            className="close"
            // data-dismiss="alert"
            // aria-label="Close"
            onClick={() =>
              document
                .getElementsByClassName("alert")[0]
                ?.classList.remove("show")
            }
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
      <section className="">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-3"></div>
            <div className="col-md-5 col-lg-6 justify-content-center align-items-center text-karla mt-5 pt-5">
              <div className="align-items-center justify-content-center mb-5 text-karla">
                <div className="card">
                  <div className="card-body">
                    <a className="navbar-brand" href="/admin">
                      <img
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Tangible IT"
                        src={TangibleITLogo}
                        alt="Tangible IT"
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "8px",
                        }}
                      />
                    </a>
                    <br />
                    <br />
                    <h1 className="page-title">Hello Admin</h1>
                    <p className="lead">Login to enter in the Dashboard.</p>
                    <div
                      className="text-karla text-danger pb-3"
                      id="messages"
                    ></div>
                    <form id="login-form" onSubmit={(e) => validateInfo(e)}>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          id="password"
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <button
                          id="btn-login"
                          type="submit"
                          className="btn btn-primary"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
